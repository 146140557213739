import React, { useEffect } from "react"
import { Helmet } from 'react-helmet';
import Layout from "../components/layout";
import Link from 'gatsby-link';

const ThankYouPage = () => {
	useEffect(() => {
	document.getElementById('lang-switch').href="/pl/dziekujemy/"
	}, []);
	return (
	<>
	<Helmet>
	<meta name="robots" content="noindex" />
	</Helmet>
	<Layout>
	<div className="container has-text-centered my-5" style={{textAlign:'center'}}>
		<h1 className="mt-5 mb-4 display-3 spec-h">Thank<br /> You!</h1>
		<br />
			<p className="lead text-success">Your message have been sent.<br /><b> Our team will soon contact You.</b></p>
			<br />
		<Link to="/"><b style={{fontSize:'1.2em'}} className="btn btn-primary">Go back...</b></Link>
		<br />
	</div>
	</Layout>
	</>
)
}

export default ThankYouPage
